<template>
    <div style="background-color: #f5faff">
        <titleBar
        style="max-height: 50px;margin-bottom: 10px;"
        :username="currentUserName"
        :pageName="pageName"
        ></titleBar>
        <!-- Dialog Block Start -->
         <div>
            <v-row>
                <v-dialog
                v-model="viewAppointmentDialog"
                persistent max-width="800" :overlay="true">
                    <div class="view_appointment_config ">
                        <div class="view_appointment_header">
                            <div>
                                <span class="viewAppointmentTitle">Reference No. : <span>{{ selectedAppointmentDetails.appointment_id }}</span></span>
                            </div>
                            <div>
                                <img style="width: 32px" @click="viewAppointmentDialog = false" src="https://s3iconimages.mymedicine.com.mm/enclosed_close_button_red_icon.svg" alt="close">
                            </div>
                        </div>
                        <div style="padding: 8px 0px;">
                            <v-divider style=" width: 100%;"></v-divider>
                        </div>
                        <div class="appointment_body_layout">
                            <div>
                                <img src="https://s3iconimages.mymedicine.com.mm/clipboard_icon.svg" alt="Appointment">
                            </div>
                            <div class="title_class">
                                <span> Appointment Details </span>
                            </div>
                            <div class="text-content">
                                <div>
                                    <span>
                                        Click the below button to copy the <span style="color: #4C4C4C;"> PIN & Invite </span> 
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        Link to send it to the Customer
                                    </span>
                                </div>
                            </div>
                            <div class="appointment-details-box">
                                <div class="appointment-details-inner-layout">
                                    <div class="appointment-box-child">
                                        <div>
                                            <img src="https://s3iconimages.mymedicine.com.mm/calender_icon.svg" alt="calender">
                                        </div>
                                        <div style="margin-left: 4px; font-weight: 600;">
                                            {{ selectedAppointmentDetails.appointment_date }}
                                        </div>
                                    </div>
                                    <div class="appointment-box-child">
                                        <div>
                                            <img src="https://s3iconimages.mymedicine.com.mm/alaram_clock_icon.svg" alt="calender">
                                        </div>
                                        <div style="margin-left: 4px; font-weight: 600;">
                                            {{ selectedAppointmentDetails.time_slot }}
                                        </div>
                                    </div>
                                    <div class="appointment-box-child">
                                        <div>
                                            <img src="https://s3iconimages.mymedicine.com.mm/calender_icon.svg" alt="calender">
                                        </div>
                                        <div style="margin-left: 4px; font-weight: 600;">
                                            {{ selectedAppointmentDetails.specialization_name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pin-segment-layout">
                                <span class="pin-title">
                                    4 digit Pin
                                </span>
                                <div class="pin-box-layout" v-if="selectedAppointmentDetails.room_pin !== undefined">
                                    <div v-for="(pin, index) in selectedAppointmentDetails.room_pin.split('')" :key="index">
                                        <div class="pin-box">
                                            {{ pin }}
                                        </div>
                                    </div>
                                </div>
                                <div style="padding: 8px;">
                                    <img src="https://s3iconimages.mymedicine.com.mm/AND_icon.svg" alt="AND">
                                </div>
                                <span class="pin-title">
                                    Invite Link
                                </span>
                                <div style="padding: 8px 0px;">
                                    <div class="invite-link-layout">
                                        <div>
                                            <img src="https://s3iconimages.mymedicine.com.mm/hyperlink_icon.svg" alt="Link">
                                        </div>
                                        <div style="margin-left: 8px;">
                                            {{ meetingLink }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div  style="padding: 12px 0px;">
                                <v-btn class="copy-button primary" @click="copyMessageTemplate(selectedAppointmentDetails)" style="background-color: #1467BF;">
                                    Click To Copy
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </v-dialog>
            </v-row>
         </div>
         <!-- Dialog Block End -->
        <v-app>
            <div style="background-color: #f5faff; height: 100vh;">
                <v-row cols="12">
                    <v-col cols="2">
                        <sideNavBar :pageName="pageName"></sideNavBar>
                    </v-col>
                    <v-col cols="10" style="overflow:scroll; height:93vh;">
                        <div style="margin: 20px 20px 20px 50px ">
                            <div class="d-flex" style="justify-content: space-between;">
                                <div style="width: 30%" align="left">
                                    <span  style="font-weight: 800;  font-size: 12pt;text-transform: uppercase;   letter-spacing: 1px;" class="pl-2">
                                    <h3>External Appointment</h3>
                                    </span>
                                </div>
                                <div class="d-flex ma-5">
                                    <div class="mr-2">
                                        <v-btn class="primary" @click="createExternalAppointmnet()" style="background-color: #1467BF;">Create Appointment</v-btn>
                                    </div>
                                    <div class="ml-2" v-show="false">
                                        <v-btn class="primary" @click="downloadAppointmentDetails()" style="background-color: #1467BF;">
                                            Download
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                            <div class="pt-4">
                                <v-data-table
                                class="tableHeaderClass"
                                :headers="tableHeader"
                                :items="appointmentDetails"
                                :loading="isTableItemsLoading">
                                <template v-slot:item.doctor_join_status="{item}" >
                                    <div v-if="item.doctor_join_status == 'Joined'">
                                        <span style="color: #1467BF;"> {{ item.doctor_join_status }} </span>
                                    </div>
                                    <div v-else>
                                        <span> {{ item.doctor_join_status }} </span>
                                    </div>
                                </template>
                                <template v-slot:item.customer_join_status="{item}" >
                                    <div v-if="item.customer_join_status == 'Joined'">
                                        <span style="color: #1467BF;"> {{ item.customer_join_status }} </span>
                                    </div>
                                    <div v-else>
                                        <span> {{ item.customer_join_status }} </span>
                                    </div>
                                </template>
                                <template v-slot:item.appointment_status="{item}" >
                                    <div v-if="item.appointment_status == 'ACTIVE'" class="appointment_status_class px-2 py-1 d-flex" style="background-color: #DAECFF; justify-content: space-around;">
                                        <span style="color: #1467BF; font-weight: 600;"> Active </span>
                                    </div>
                                    <div v-else-if="item.appointment_status == 'INPROGRESS'" class="appointment_status_class px-2 py-1 d-flex" style="background-color: #BAAE2A14; justify-content: space-around;">
                                        <span style="color: #C59C2B; font-weight: 600;"> In Progress </span>
                                    </div>
                                    <div v-else-if="item.appointment_status == 'CANCELLED'" class="appointment_status_class px-2 py-1 d-flex" style="background-color: #FFE4E5; justify-content: space-around;">
                                        <span style="color: #FF666C; font-weight: 600;"> Cancelled </span>
                                    </div>
                                    <div v-if="item.appointment_status == 'COMPLETED'" class="appointment_status_class px-2 py-1 d-flex" style="background-color: #27AE6014; justify-content: space-around;">
                                        <span style="color: #27AE60; font-weight: 600;"> Completed </span>
                                    </div>
                                </template>
                                <template v-slot:item.action="{item}"  class="d-flex">
                                    <div class="d-flex">
                                        <div>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                <a style="width: 100%" icon depressed v-on="on">
                                                    <img src="https://s3iconimages.mymedicine.com.mm/view_primary_icon.svg" @click="viewParticularAppointment(item)" style="height: 24px; width: 24px;"/>
                                                </a>
                                                </template>
                                                <span>View</span>
                                            </v-tooltip>
                                        </div>
                                        <div v-if="['ACTIVE', 'INPROGRESS'].includes(item.appointment_status)" class="ml-1">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                <a style="width: 100%" icon depressed v-on="on">
                                                    <img src="https://s3iconimages.mymedicine.com.mm/copy_primary_icon.svg" @click="copyMessageTemplate(item)" style="height: 24px; width: 24px;"/>
                                                </a>
                                                </template>
                                                <span>Click to Copy</span>
                                            </v-tooltip>
                                        </div>
                                        <div  class="ml-1" v-if="item.appointment_status == 'COMPLETED'">
                                            <v-tooltip v-if="item.isPrescriptionExist && !downloadLoader" bottom>
                                                <template v-slot:activator="{ on }">
                                                <a style="width: 100%" icon depressed v-on="on">
                                                    <img src="https://s3iconimages.mymedicine.com.mm/downloadIcon.svg" @click="downloadPrescription(item)" style="height: 24px; width: 24px; background-color: greenyellow; border-radius: 50%; padding: 5px; border: 1px solid greenyellow;"/>
                                                </a>
                                                </template>
                                                <span>Download Prescription</span>
                                            </v-tooltip>
                                            <v-progress-circular v-if="downloadLoader" indeterminate color="primary"></v-progress-circular>
                                        </div>
                                        <div v-if="['ACTIVE'].includes(item.appointment_status)" class="ml-1">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                <a style="width: 100%" icon depressed v-on="on">
                                                    <img src="https://s3iconimages.mymedicine.com.mm/delete_red_icon.svg" @click="cancelAppointment(item)" style="height: 24px; width: 24px;"/>
                                                </a>
                                                </template>
                                                <span>Cancel</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </template>
                                </v-data-table>
                            </div>
                            <div>
                                <v-snackbar
                                v-model="messageSnackbar"
                                vertical
                                :timeout="3000"
                                >
                                <div class="text-subtitle-1 pb-2">Messaged Copied</div>
                                <p>{{ copiedMessage }}</p>
                                <template v-slot:actions>
                                    <v-btn
                                    color="indigo"
                                    variant="text"
                                    style="background-color: #EB5757;"
                                    @click="messageSnackbar = false"
                                    >
                                    Close
                                    </v-btn>
                                </template>
                                </v-snackbar>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-app>
    </div>
</template>
<script>
import { VDataTableHeader } from 'vuetify/lib';
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';
export default {
    name: "ExternalAppointmentList",
    components: {
        titleBar: () => import('../../navbar.vue'),
        sideNavBar: () => import('../../sideBar.vue'),
    },
    data() {
        return {
            pageName: 'External Consultation',
            downloadLoader: false,
            currentUserName: null,
            currentUserId: null,
            appointmentDetails: [],
            tableHeader:[
                { text: 'Reference No.', value: 'appointment_id', sortable: false},
                { text: 'Phone No.', value: 'phone_number', sortable: false},
                { text: 'Communication Medium', value: 'communication_medium', sortable: false},
                { text: 'Doctor Name', value: 'doctor_name', sortable: false},
                { text: 'Appointment Date', value: 'appointment_date', sortable: false},
                { text: 'Time Slot', value: 'time_slot', sortable: false},
                { text: 'Consultation Type', value: 'consultation_type', sortable: false},
                { text: 'Doctor Status', value: 'doctor_join_status', sortable: false},
                { text: 'Customer Status', value: 'customer_join_status', sortable: false},
                { text: 'Appointment Status', value: 'appointment_status', sortable: false},
                { text: 'Action', value: 'action', sortable: false},
            ],
            viewAppointmentDialog: false,
            pinNumber: '0909', 
            meetingLink: 'invite.video/telio.638352735363.us-hyperlink',
            messageSnackbar: false,
            copiedMessage: null,
            isTableItemsLoading: false,
            selectedAppointmentDetails: {},

        }
    },
    beforeMount() {
        this.currentUser = this.$cookies.get("token");
        if (!this.currentUser) {
            this.$router.push({
                name: "AdminLogin",
            });
        } else {
            this.isTableItemsLoading = true;
            var getAdminBody = {
                token : this.currentUser,
                typeOfUser : "ADMIN",
                adminType: "ADMIN"
            };
            axios_auth_instance_admin.post("/getAdmin", getAdminBody)
            .then((getAdminResponse) => {
                this.currentUserName = getAdminResponse.data.data.admin_name;
                this.currentUserId = getAdminResponse.data.data._id;
                // this.renderTableCustomerActions('Active')
            })
            .catch((getAdminError) => {
                handleError(getAdminError, this.$router, 'getAdmin', 'AdminLogin', 'token');
            });
        }
    },
    mounted() {
        // Get API to get the appointment details.
        let reqParams = {
            token: this.currentUser,
            typeOfUser : "ADMIN"
        }
        axios_auth_instance_admin.post("/getCPIAppointment", reqParams)
        .then((getCPIAppointmentResponse) => {
            console.log(getCPIAppointmentResponse);
            this.appointmentDetails = getCPIAppointmentResponse.data.data.map(({
                _id,
                date_of_appointment, 
                time_slot, 
                status, 
                phoneNumber, 
                type_of_appointment, 
                specialization_name,
                room_password,
                communication_medium,
                videoCallDetails,
                doctor_name,
                appointment_reference_number, prescription_reference_number}) => ({
                    _id: _id,
                    appointment_id: appointment_reference_number,
                    phone_number: phoneNumber,
                    communication_medium: communication_medium,
                    appointment_date: date_of_appointment,
                    time_slot: time_slot,
                    consultation_type: type_of_appointment,
                    doctor_join_status: videoCallDetails.doctor_first_joined_time !== '' ? 'Joined' : 'Yet To join',
                    customer_join_status: videoCallDetails.customer_first_joined_time !== '' ? 'Joined' : 'Yet To join',
                    appointment_status: status,
                    room_pin: room_password,
                    specialization_name: specialization_name,
                    isPrescriptionExist: prescription_reference_number != '',
                    doctor_name: doctor_name
                })
            )
            this.isTableItemsLoading = false;
        })
        .catch((getCPIAppointmentError) => {
            handleError(getAdminError, this.$router, 'getAdmin', 'AdminLogin', 'token');
        });
    },
    methods: {
        createExternalAppointmnet() {
            this.$router.push({
                name: 'CreateCPIAppointment'
            });
        },
        copyMessageTemplate( appointmentMessage) {
            this.meetingLink = process.env.VUE_APP_CPI_ACCESS_URL + '/appointmentDetails/' + appointmentMessage._id;
            let message = `Your appointment is confirmed!

Ref No: ${appointmentMessage.appointment_id}
Date: ${appointmentMessage.appointment_date}
Time: ${appointmentMessage.time_slot}
Doctor: ${appointmentMessage.specialization_name}
Joining Link: ${this.meetingLink}
PIN: ${appointmentMessage.room_pin}

Please join 5 mins early using the given link and PIN.`;
            navigator.clipboard.writeText(message).then(() => {
                this.copiedMessage = message
                this.messageSnackbar = true
            }).catch(err => {
                console.error("Failed to copy: ", err);
            });            
        },
        cancelAppointment(appointment) {
            let reqParams = {
                token: this.currentUser,
                typeOfUser : "ADMIN",
                appointmentID: appointment._id
            }
            axios_auth_instance_admin.post("/cancelCPIAppointment", reqParams)
            .then((cancelCPIAppointmentResponse) =>{
                console.log('Appointment Cancellation successfull', cancelCPIAppointmentResponse);
                alert(`Appointment ${appointment.appointment_id} has been cancelled Successfully !!`);
            }).catch((cancelAppointmentError) => {
                console.log('Appointment Cancellation Error', cancelAppointmentError);
                alert(`Failed to cancel appointment with Appointment ID ${appointment.appointment_id}.`);
            })
        },
        viewParticularAppointment( particularAppointmentDetail ) {
            this.selectedAppointmentDetails = particularAppointmentDetail;
            this.meetingLink = process.env.VUE_APP_CPI_ACCESS_URL + '/appointmentDetails/' + particularAppointmentDetail._id;
            this.viewAppointmentDialog = true
        },
        async downloadPrescription(appointment) {
            this.downloadLoader = true;
            try { 
                let reqParams = {
                    token: this.currentUser,
                    typeOfUser : "ADMIN",
                    appointmentID: appointment._id
                }
                let apiResponse = await axios_auth_instance_admin.post("/adminDownloadPrescription", reqParams);
                let url = apiResponse.data.url;
                window.open(url, "_blank")
            } catch (error) {
                alert('Something went wrong');
                console.log(error);
            } finally {
                this.downloadLoader = false;
            }
        }
    }
}
</script>
<style scoped>
.appointment_status_class {
    border-radius: 8px;
}
.view_appointment_config {
    background-color: white;
    padding: 16px;
    border-radius: 12px;
}
.viewAppointmentTitle {
    font-size: 24px;
    font-weight: 600;
}
.view_appointment_header {
    display: flex;
    justify-content: space-between;
}
.appointment_body_layout {
    padding:16px
}
.title_class {
    color: #4F4F4F;
    font-size: 28px;
    font-weight: 700;
}
.text-content {
    padding: 8px 0px;
    color: #828282;
    text-align: center;
    font-size: 22px;
}
.appointment-details-box {
    width: 70%;
    border: #E0E0E0 1px solid;
    border-radius: 12px;
    
    padding:8px;
    margin: 8px auto;
    background-color: #F4F6FA;
}
.appointment-details-inner-layout {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.appointment-box-child {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.pin-segment-layout {
    padding: 16px 0px;
}
.pin-title {
    color: #373838;
    font-weight: 500;
    font-size: 22px;
}
.pin-box-layout {
    display: flex;
    padding: 8px 0px;
    margin: auto;
    justify-content: center;
}
.pin-box {
    border: 1px solid #E0E0E0;
    background-color: #F4F6FA;
    border-radius: 6px;
    padding: 4px 8px;
    margin-right: 6px;
}
.invite-link-layout {
    border: 1px solid #E0E0E0;
    background-color: #F4F6FA;
    border-radius: 12px;
    display: flex;
    align-items: center;
    margin: auto;
    padding: 12px 16px;
}
.copy-button {
    color: white;
    background-color: #1467BF !important;
    border-radius: 8px;
}
</style>
<style>
.tableHeaderClass .v-data-table-header th{
    background: #1467bf !important;
    /* #F4F6FA !important; */
    color: #FFFFFF !important;
    font-size: 8pt !important;
    font-weight: 500 !important;
  }
</style>